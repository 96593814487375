import { createStore } from "vuex";
export default createStore({
  state: {
    isLoading: false,
  },
  mutations: {
    Loading(state, payload) {
      state.isLoading = payload;
    },
  },
  actions: {},
  getters: {
    isLoading: (state) => {
      return state.isLoading;
    },
  },
});
