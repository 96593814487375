<template>
  <div>
  <div class="d-flex justify-content-around flex-lg-row flex-column">
    <div class="d-flex flex-column">
      <div class="d-flex flex-row">
        <img
          src="@/assets/img/main/logo_appicon2.png"
          style="width: 120px; height: 120px"
        />
        <div class="fw-bold d-flex align-items-center">
          Betnet <br />{{ $t("text_introduce172") }} <br />{{
            $t("text_introduce173")
          }}
        </div>
      </div>
      <div class="footer_img">
        <img src="@/assets/img/main/mga.png" />
        <img src="@/assets/img/main/pagcor.png" />
        <img src="@/assets/img/main/gambling_commission.png" />
        <img src="@/assets/img/main/gaming_curacao.png" />
      </div>
    </div>

    <div
      class="d-flex flex-lg-row flex-column mt-lg-0 mt-5 px-3 px-lg-0"
      style="gap: 5rem"
    >
      <div>
        <p class="fw-bold">{{ $t("solution_pro") }}</p>
        <ul class="mt-4 ps-0">
          <li class="mt-xl-2 mt-4">
            <a class="my-text-black text-under-hover">{{
              $t("text_introduce70")
            }}</a>
          </li>
          <li class="mt-xl-2 mt-4">
            <a class="my-text-black text-under-hover">{{
              $t("text_introduce71")
            }}</a>
          </li>
          <li class="mt-xl-2 mt-4">
            <a class="my-text-black text-under-hover">{{
              $t("text_introduce72")
            }}</a>
          </li>
          <li class="mt-xl-2 mt-4">
            <a class="my-text-black text-under-hover">{{
              $t("text_introduce73")
            }}</a>
          </li>
          <li class="mt-xl-2 mt-4">
            <a class="my-text-black text-under-hover">{{
              $t("text_introduce74")
            }}</a>
          </li>
        </ul>
      </div>
      <div>
        <p class="fw-bold">{{ $t("text_introduce75") }}</p>
        <div
          class="d-lg-grid mt-3"
          style="gap: 2rem; grid-template-columns: repeat(2, minmax(0, 1fr))"
        >
          <ul class="ps-0 mb-xl-auto">
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce76")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce77")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce78")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce79")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce80")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce81")
              }}</a>
            </li>
          </ul>
          <ul class="ps-0">
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce82")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce83")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce84")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce85")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce86")
              }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <p class="fw-bold">{{ $t("text_introduce174") }}</p>
        <div
          class="d-lg-grid mt-3"
          style="gap: 2rem; grid-template-columns: repeat(2, minmax(0, 1fr))"
        >
          <ul class="ps-0 mb-xl-auto">
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce175")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce176")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce177")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce178")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce179")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce180")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce181")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce182")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce183")
              }}</a>
            </li>
          </ul>
          <ul class="ps-0">
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce185")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce186")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce187")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce188")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce189")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce190")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce191")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce192")
              }}</a>
            </li>
            <li class="mt-xl-2 mt-4">
              <a class="my-text-black text-under-hover">{{
                $t("text_introduce184")
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="footer_img footer_img2 mt-5 text-center" >
    <img src="@/assets/img/footer/nfa.png" />
    <img src="@/assets/img/footer/fca.png"  />
    <img src="@/assets/img/footer/asic.png"  />
    <img src="@/assets/img/footer/finma.png"  />
    <img src="@/assets/img/footer/argentina.png"  />
    <img src="@/assets/img/footer/france.png"  />
    <img src="@/assets/img/footer/manchester.png"  />
    <img src="@/assets/img/footer/lakers.png"  />
    <img src="@/assets/img/footer/newcastle.png"  />
    <img src="@/assets/img/footer/fiba.png"  />
    <img src="@/assets/img/footer/premier_league.png"  />
  </div> -->
</div>
</template>
