<template>
  <div class="d-flex cp-2 justify-content-around lg-mx-8">
    <div
      class="pt-xl-5 col-xl-3"
      :class="flag_w_h == 'web' ? '' : 'border-start border-1 ps-3 mt-4'"
    >
      <div
        class="d-inline-block pb-3 w-100 mb-4"
        :class="flag_w_h == 'web' ? 'border-bottom border-1' : ''"
      >
        <span class="fw-bold fs-f-125">{{ $t("text_introduce87") }}</span>
      </div>
      <ul class="pb-2">
        <li class="mb-4 pe-4 c-pointer">
          <a class="">
            <span
              class="d-inline-block arrow-animation my-text-black position-relative"
              >{{ $t("text_introduce88") }}</span
            >
            <div class="text-top-grey fs-f-0875 text-top-hover">
              {{ $t("text_introduce89") }}
            </div>
          </a>
        </li>
        <li class="mb-4 pe-4">
          <a class="">
            <span
              class="d-inline-block arrow-animation my-text-black position-relative"
              >{{ $t("text_introduce90") }}</span
            >
            <div class="text-top-grey fs-f-0875 text-top-hover">
              {{ $t("text_introduce91") }}
            </div>
          </a>
        </li>
        <li class="mb-4 pe-4">
          <a class="">
            <span
              class="d-inline-block arrow-animation my-text-black position-relative"
              >{{ $t("text_introduce92") }}</span
            >
            <div class="text-top-grey fs-f-0875 text-top-hover">
              {{ $t("text_introduce93") }}
            </div>
          </a>
        </li>
        <li class="mb-4 pe-4">
          <a class="">
            <span
              class="d-inline-block arrow-animation my-text-black position-relative"
            >
              {{ $t("text_introduce94") }}</span
            >
            <div class="text-top-grey fs-f-0875 text-top-hover">
              {{ $t("text_introduce95") }}
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div
      class="pt-xl-5 col-xl-3"
      :class="flag_w_h == 'web' ? '' : 'border-start border-1 ps-3 mt-4'"
    >
      <div
        class="d-inline-block pb-3 w-100 mb-4"
        :class="flag_w_h == 'web' ? 'border-bottom border-1' : ''"
      >
        <span class="fw-bold fs-f-125">{{ $t("text_introduce96") }}</span>
      </div>
      <ul class="pb-2">
        <li class="mb-4 pe-4 c-pointer">
          <a class="">
            <span
              class="d-inline-block arrow-animation my-text-black position-relative"
              >{{ $t("text_introduce97") }}</span
            >
            <div class="text-top-grey fs-f-0875 text-top-hover">
              {{ $t("text_introduce98") }}
            </div>
          </a>
        </li>
        <li class="mb-4 pe-4">
          <a class="">
            <span
              class="d-inline-block arrow-animation my-text-black position-relative"
              >{{ $t("text_introduce99") }}</span
            >
            <div class="text-top-grey fs-f-0875 text-top-hover">
              {{ $t("text_introduce100") }}
            </div>
          </a>
        </li>
        <li class="mb-4 pe-4">
          <a class="">
            <span
              class="d-inline-block arrow-animation my-text-black position-relative"
              >{{ $t("text_introduce101") }}</span
            >
            <div class="text-top-grey fs-f-0875 text-top-hover">
              {{ $t("text_introduce102") }}
            </div>
          </a>
        </li>
        <li class="mb-4 pe-4">
          <a class="">
            <span
              class="d-inline-block arrow-animation my-text-black position-relative"
            >
              {{ $t("text_introduce103") }}</span
            >
            <div class="text-top-grey fs-f-0875 text-top-hover">
              {{ $t("text_introduce104") }}
            </div>
          </a>
        </li>
        <li class="mb-4 pe-4">
          <a class="">
            <span
              class="d-inline-block arrow-animation my-text-black position-relative"
              >{{ $t("text_introduce105") }}</span
            >
            <div class="text-top-grey fs-f-0875 text-top-hover">
              {{ $t("text_introduce106") }}
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div
      class="pt-xl-5 col-xl-3"
      :class="flag_w_h == 'web' ? '' : 'border-start border-1 ps-3 mt-4'"
    >
      <div
        class="d-inline-block pb-3 w-100 mb-4"
        :class="flag_w_h == 'web' ? 'border-bottom border-1' : ''"
      >
        <span class="fw-bold fs-f-125">{{ $t("text_introduce80") }}</span>
      </div>
      <ul class="pb-2">
        <li class="mb-4 pe-4 c-pointer">
          <a class="">
            <span
              class="d-inline-block arrow-animation my-text-black position-relative"
              >{{ $t("text_introduce108") }}</span
            >
            <div class="text-top-grey fs-f-0875 text-top-hover">
              {{ $t("text_introduce109") }}
            </div>
          </a>
        </li>
        <li class="mb-4 pe-4">
          <a class="">
            <span
              class="d-inline-block arrow-animation my-text-black position-relative"
              >{{ $t("text_introduce110") }}</span
            >
            <div class="text-top-grey fs-f-0875 text-top-hover">
              {{ $t("text_introduce111") }}
            </div>
          </a>
        </li>
        <li class="mb-4 pe-4">
          <a class="">
            <span
              class="d-inline-block arrow-animation my-text-black position-relative"
              >{{ $t("text_introduce112") }}</span
            >
            <div class="text-top-grey fs-f-0875 text-top-hover">
              {{ $t("text_introduce113") }}
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div
      class="pt-xl-5 col-xl-3"
      :class="flag_w_h == 'web' ? '' : 'border-start border-1 ps-3 mt-4'"
    >
      <div
        class="d-inline-block pb-3 w-100 mb-4"
        :class="flag_w_h == 'web' ? 'border-bottom border-1' : ''"
      >
        <span class="fw-bold fs-f-125">{{ $t("text_introduce114") }}</span>
      </div>
      <ul class="pb-2">
        <li class="mb-4 pe-4 c-pointer">
          <a class="">
            <span
              class="d-inline-block arrow-animation my-text-black position-relative"
              >{{ $t("text_introduce115") }}</span
            >
            <div class="text-top-grey fs-f-0875 text-top-hover">
              {{ $t("text_introduce116") }}
            </div>
          </a>
        </li>
        <li class="mb-4 pe-4">
          <a class="">
            <span
              class="d-inline-block arrow-animation my-text-black position-relative"
              >{{ $t("text_introduce117") }}</span
            >
            <div class="text-top-grey fs-f-0875 text-top-hover">
              {{ $t("text_introduce118") }}
            </div>
          </a>
        </li>
        <li class="mb-4 pe-4">
          <a class="">
            <span
              class="d-inline-block arrow-animation my-text-black position-relative"
              >{{ $t("text_introduce119") }}</span
            >
            <div class="text-top-grey fs-f-0875 text-top-hover">
              {{ $t("text_introduce120") }}
            </div>
          </a>
        </li>
        <li class="mb-4 pe-4">
          <a class="">
            <span
              class="d-inline-block arrow-animation my-text-black position-relative"
            >
              {{ $t("text_introduce121") }}</span
            >
            <div class="text-top-grey fs-f-0875 text-top-hover">
              {{ $t("text_introduce122") }}
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: { flag_w_h: String },
};
</script>
