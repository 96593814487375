<template>
  <div
    class="position-fixed start-0 top-0 my-bg-white w-100"
    style="z-index: 2"
  >
    <div class="container">
      <div class="d-flex align-items-center">
        <img
          src="@/assets/logo.png"
          width="200"
          class="me-lg-5 me-2"
          @click="this.$router.push('/')"
        />
        <div>
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 d-xl-flex flex-row d-none">
            <li
              class="nav-item dropdown d-flex align-items-center c-pointer text-under-hover"
              @click="flag_web_pro = !flag_web_pro"
            >
              <a class="nav-link my-text-black">{{ $t("solution_pro") }}</a>
              <img
                src="@/assets/img/main/down-arrow.png"
                width="20"
                height="20"
                :class="flag_web_pro ? 'r-180' : ''"
              />
            </li>
            <li
              class="nav-item c-pointer text-under-hover"
              @click="this.$router.push('/price')"
            >
              <a class="nav-link my-text-black">{{ $t("text_price") }}</a>
            </li>
            <li
              class="nav-item c-pointer text-under-hover"
              @click="this.$router.push('/merchant')"
            >
              <a class="nav-link my-text-black">{{
                $t("text_introduce193")
              }}</a>
            </li>
            <li
              class="nav-item c-pointer text-under-hover"
              @click="this.$router.push('/company')"
            >
              <a class="nav-link my-text-black">{{
                $t("text_introduce194")
              }}</a>
            </li>
            <li
              class="nav-item c-pointer text-under-hover"
              @click="this.$router.push('/ecology')"
            >
              <a class="nav-link my-text-black">{{
                $t("text_introduce279")
              }}</a>
            </li>
          </ul>

          <div
            class="position-absolute my-bg-white w-100 start-0 border-1 border-top"
            style="top: 4rem; z-index: 1"
            v-show="flag_web_pro"
          >
            <topnavview :flag_w_h="'web'" />
          </div>
        </div>
        <div class="ms-auto">
          <ul
            class="d-xl-none d-flex align-items-center"
            style="margin-right: -10px"
          >
            <li @click="click_href('reg')">
              <a class="me-4 my-text-black"
                ><u>{{ $t("text_free_use") }}</u></a
              >
            </li>
            <li>
              <button
                class="d-flex cursor-pointer flex-column align-items-center gap-1 bg-transport border-0 justify-content-center"
                type="button"
                style="height: 3rem; width: 3rem"
                @click="flag_mobile_nav = !flag_mobile_nav"
              >
                <div
                  class="transition-all duration-500 bg-black"
                  style="width: 1.125rem; height: 0.125rem"
                  :class="flag_mobile_nav ? 'rotate-45 rotate-all-45' : ''"
                ></div>
                <div
                  class="transition-all duration-500 bg-black"
                  style="width: 1.125rem; height: 0.125rem"
                  :class="flag_mobile_nav ? 'opacity-0' : ''"
                ></div>
                <div
                  class="transition-all duration-500 bg-black"
                  style="width: 1.125rem; height: 0.125rem"
                  :class="flag_mobile_nav ? 'rotate--45 rotate-all-45' : ''"
                ></div>
              </button>
            </li>
          </ul>

          <ul class="d-xl-flex d-none align-items-center">
            <li class="me-4 c-pointer text-under-hover">
              <a class="my-text-black" @click="click_href()">{{
                $t("text_login")
              }}</a>
            </li>
            <li
              class="my-bg-black my-text-white rounded-5 py-2 px-4 fw-bold bg-hover-grey c-pointer"
              @click="click_href('reg')"
            >
              <a>{{ $t("text_free_use") }}</a>
            </li>
          </ul>
        </div>
      </div>

      <!-- 手機板 -->
      <div
        class="position-fixed start-0 my-bg-white w-100 border-top border-2 bottom-0 flex-column overflow-y-auto"
        :class="flag_mobile_nav ? 'd-flex' : 'd-none'"
        style="top: 4rem"
      >
        <ul class="ms-4 my-5">
          <li
            class="nav-item dropdown mb-4"
            @click="flag_h5_pro = !flag_h5_pro"
          >
            <div class="d-flex align-items-center">
              <a class="nav-link my-text-black fs-f-125 fw-bold">{{
                $t("solution_pro")
              }}</a>
              <img
                src="@/assets/img/main/down-arrow.png"
                width="20"
                height="20"
                :class="flag_h5_pro ? 'r-180' : ''"
              />
            </div>
            <div v-show="flag_h5_pro">
              <topnavview class="flex-column" :flag_w_h="'h5'" />
            </div>
          </li>
          <li class="nav-item mb-4" @click="this.$router.push('/price')">
            <a class="nav-link my-text-black fs-f-125 fw-bold">{{
              $t("text_price")
            }}</a>
          </li>
          <li class="nav-item mb-4" @click="this.$router.push('/merchant')">
            <a class="nav-link my-text-black fs-f-125 fw-bold">{{
              $t("text_introduce193")
            }}</a>
          </li>
          <li class="nav-item mb-4" @click="this.$router.push('/company')">
            <a class="nav-link my-text-black fs-f-125 fw-bold">{{
              $t("text_introduce194")
            }}</a>
          </li>
          <li class="nav-item" @click="this.$router.push('/ecology')">
            <a class="nav-link my-text-black fs-f-125 fw-bold">{{
              $t("text_introduce279")
            }}</a>
          </li>
        </ul>
        <div
          class="mt-auto w-100 p-3 border-1 border-top"
          :class="screen_width() ? 'd-flex gap-3' : 'd-block'"
        >
          <div
            class="my-bg-white my-text-black w-100 text-center py-2 border-3 rounded-5 boder-black border-style mb-3 fw-bold"
            @click="click_href()"
          >
            {{ $t("text_login") }}
          </div>
          <div
            class="my-bg-black my-text-white w-100 text-center py-2 border-3 rounded-5 boder-black border-style mb-3 fw-bold"
            @click="click_href('reg')"
          >
            {{ $t("text_free_use") }}
          </div>
        </div>
      </div>
      <!-- end -->
    </div>
  </div>
</template>

<script>
import topnavview from "./top_nav.vue";
export default {
  name: "maintop",
  data() {
    return {
      flag_web_pro: false,
      flag_h5_pro: false,
      flag_mobile_nav: false,
    };
  },
  created() {},
  mounted() {},
  components: {
    topnavview,
  },
  computed: {},
  methods: {
    click_href(id) {
      if (id == "reg") window.open("https://betnetbk.starlink.pw/reg.aspx");
      else window.open("https://betnetbk.starlink.pw/");
    },
    screen_width() {
      if (screen.width >= 768) return true;
      else return false;
    },
  },
  watch: {},
};
</script>
