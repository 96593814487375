import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/price",
    name: "price",
    component: () =>
      import("../views/price.vue"),
  },
  {
    path: "/merchant",
    name: "merchant",
    component: () =>
      import("../views/merchant.vue"),
  },
  {
    path: "/company",
    name: "company",
    component: () =>
      import("../views/company.vue"),
  },
  {
    path: "/ecology",
    name: "ecology",
    component: () =>
      import("../views/ecology.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  next();
});
export default router;
