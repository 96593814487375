<template>
  <div class="container p-2">
    <div class="col-12 d-flex justify-content-between">
      <div class="col-lg-5 col-12 mt-lg-5 mt-3">
        <!-- <a
              class="my-text-black py-2 px-4 border-grey border-1 border-style rounded-5 mb-5 hover-border-grey d-lg-inline-block d-none"
              type="button"
            >
              <span class="me-2 d-inline-block border-arrow"></span>
              {{ $t("text_look") }}</a
            > -->
        <h1 class="fw-bold fs-f-3rem lg-text-6xl">
          {{ $t("text_global") }}
        </h1>
        <p class="lg-fs-f-normal fs-f-1-125rem mt-lg-0 mt-3">
          {{ $t("text_introduce1") }}
        </p>

        <div class="d-lg-block d-none">
            <button
              class="my-bg-black my-text-white rounded-5 px-4 fw-bold fs-f-18 bg-hover-grey border-0 ls-001 text-nowrap w-100"
              name="button"
              style="padding-top: 16px; padding-bottom: 16px"
              type="button"
              @click="click_href('reg')"
            >
              {{ $t("text_free_use") }}
            </button>
          </div>
        <!-- <div
          class="my-bg-white border-grey rounded-5 d-flex justify-content-between ps-4 pe-1 border-1 border-style py-lg-1 py-3"
          :class="flag_email_border ? 'boder-black' : ''"
        >
          <input
            type="email"
            name="email"
            autocomplete="on"
            class="border-0 input-text-focus"
            :placeholder="$t('text_introduce169')"
            style="width: 18rem"
            @click="flag_email_border = true"
          />
          <div class="d-lg-block d-none">
            <button
              class="my-bg-black my-text-white rounded-5 px-4 fw-bold fs-f-18 bg-hover-grey border-0 ls-001 text-nowrap"
              name="button"
              style="padding-top: 16px; padding-bottom: 16px"
              type="button"
              @click="click_href('reg')"
            >
              {{ $t("text_free_use") }}
            </button>
          </div>
        </div> -->

        <div class="d-lg-none d-block">
          <button
            class="my-bg-black my-text-white rounded-5 px-4 fw-bold fs-f-18 bg-hover-grey border-0 w-100 mt-3"
            name="button"
            style="padding-top: 16px; padding-bottom: 16px"
            type="button"
            @click="click_href('reg')"
          >
            {{ $t("text_free_use") }}
          </button>
        </div>

        <!-- <p class="fs-f-12 mt-3">{{ $t("text_introduce2") }}</p> -->
      </div>
      <div class="col-5 d-lg-block d-none my-auto">
        <img src="@/assets/img/main/site_pic1.png" class="w-100" />
        <!-- <div
              class="d-flex justify-content-between w-100 image-transport h-100"
            >
              <div class="w-50-gutter transition-homepage">
                <div class="d-flex flex-column">
                  <div class="m-3">
                    <img src="@/assets/img/banner/1.webp" class="w-100" />
                  </div>
                  <div class="m-3">
                    <img src="@/assets/img/banner/1.webp" class="w-100" />
                  </div>
                  <div class="m-3">
                    <img src="@/assets/img/banner/1.webp" class="w-100" />
                  </div>
                  <div class="m-3">
                    <img src="@/assets/img/banner/1.webp" class="w-100" />
                  </div>
                  <div class="m-3">
                    <img src="@/assets/img/banner/1.webp" class="w-100" />
                  </div>
                </div>
              </div>
              <div class="w-50-gutter transition-homepage">
                <div class="d-flex flex-column">
                  <div class="m-3">
                    <img src="@/assets/img/banner/2.webp" class="w-100" />
                  </div>
                  <div class="m-3">
                    <img src="@/assets/img/banner/2.webp" class="w-100" />
                  </div>
                  <div class="m-3">
                    <img src="@/assets/img/banner/2.webp" class="w-100" />
                  </div>
                  <div class="m-3">
                    <img src="@/assets/img/banner/2.webp" class="w-100" />
                  </div>
                  <div class="m-3">
                    <img src="@/assets/img/banner/2.webp" class="w-100" />
                  </div>
                </div>
              </div>
            </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "hometop",
  data() {
    return {
      flag_email_border: false,
    };
  },
  created() {},
  mounted() {},
  components: {},
  computed: {},
  methods: {
    click_href(id) {
      if (id == "reg") window.open("https://betnetbk.starlink.pw/reg.aspx");
      else window.open("https://betnetbk.starlink.pw/");
    },
  },
  watch: {},
};
</script>
