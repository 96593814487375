<template>
  <div class="mb-5 position-relative">
    <maintopview />

    <div
      class="bg-linear-green pt-global-header pb-global-header before-bg-linear-white after-bg-linear-white bg-linear-white-upsitedown"
    >
      <div
        class="h-global-header position-absolute top-0 start-0 w-100 bg-linear-white"
      ></div>

      <hometop />
    </div>

    <div class="">
      <div class="py-5 container">
        <div class="d-flex align-items-center md-flex-wrap lg-flex-wrap">
          <div class="d-lg-none d-block">
            <h2 class="md-fs-f-46 fs-f-2-5rem">
              {{ $t("text_introduce3") + $t("text_introduce4") }}
            </h2>
          </div>

          <img
            src="@/assets/img/main/site_pic2.png"
            class="w-100 w-lg-50 p-lg-5"
          />
          <div class="lg-mt-8">
            <div class="d-lg-block d-none">
              <h2 class="md-fs-f-46 fs-f-2-5rem">
                {{ $t("text_introduce3") }}
                <div class="linear_g_p">{{ $t("text_introduce4") }}</div>
              </h2>
            </div>
            <div class="mt-5 d-flex flex-wrap">
              <div
                class="before-linear position-relative ps-lg-5 pe-lg-3 px-3 py-0 mb-5 d-flex flex-column col-6"
              >
                <span class="fs-f-2-5rem md-fs-f-1-875rem">{{
                  $t("text_introduce5") +
                  $t("text_introduce6") +
                  $t("text_introduce7")
                }}</span>
                <div class="md-fs-f-1rem fs-f-1-125rem">
                  {{ $t("text_introduce8") }}
                </div>
              </div>
              <div
                class="before-linear position-relative ps-lg-5 pe-lg-3 px-3 py-0 mb-5 d-flex flex-column col-6"
              >
                <span class="fs-f-2-5rem md-fs-f-1-875rem">140+</span>
                <div class="md-fs-f-1rem fs-f-1-125rem">
                  {{ $t("text_introduce9") }}
                </div>
              </div>
              <div
                class="before-linear position-relative ps-lg-5 pe-lg-3 px-3 py-0 mb-5 d-flex flex-column col-6"
              >
                <span class="fs-f-2-5rem md-fs-f-1-875rem">{{
                  $t("text_introduce5") +
                  $t("text_introduce6") +
                  $t("text_introduce13")
                }}</span>
                <div class="md-fs-f-1rem fs-f-1-125rem">
                  {{ $t("text_introduce10") }}
                </div>
              </div>
              <div
                class="before-linear position-relative ps-lg-5 pe-lg-3 px-3 py-0 mb-5 d-flex flex-column col-6"
              >
                <div>
                  <p class="md-fs-f-1rem fs-f-1-125rem">
                    {{ $t("text_introduce172") + $t("text_introduce173") }}
                  </p>
                  <p class="md-fs-f-1rem fs-f-1-125rem">
                    {{ $t("text_introduce171") }}
                  </p>
                  <!-- <sup class="currency">{{ $t("text_introduce14") }}</sup> -->
                </div>
                <!-- <div class="md-fs-f-1rem fs-f-1-125rem">
                  {{ $t("text_introduce15") }}
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          <h6>{{ $t("text_introduce16") }}</h6>
          <div class="d-flex cp scroll-new p-1">
            <a
              class="p-4 cursor-pointer group_hover box-shadow_black col-75 w-md-80 rounded-3 flex-shrink-0 snap-center w-lg-25"
              ><img
                src="@/assets/img/main/infinity-ea665cc8ef4b2379465495ba43cd5cdbba88cd70dd51c6fa59aec331fd2336be.svg"
                class="mb-4"
              />
              <h3 class="fs-f-1-125rem my-text-black">
                {{ $t("text_introduce17")
                }}<svg
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                  focusable="false"
                  class="opacity-o-0 w-1 h-4 d-inline-block transition-all duration-500 hover-opacity-100"
                >
                  <path
                    d="M17.707 9.293l-5-5a.999.999 0 10-1.414 1.414L14.586 9H3a1 1 0 100 2h11.586l-3.293 3.293a.999.999 0 101.414 1.414l5-5a.999.999 0 000-1.414z"
                    fill="currentColor"
                  ></path>
                </svg>
              </h3>
              <p class="text-grey">{{ $t("text_introduce18") }}</p></a
            >
            <a
              class="p-4 cursor-pointer group_hover box-shadow_black col-75 w-md-80 rounded-3 flex-shrink-0 snap-center w-lg-25"
              ><img
                src="@/assets/img/main/partners-d453b53d9ff10a630080d24563478935f3d1b10de8eb15bef9f13fe58be3f8de.svg"
                class="mb-4"
              />
              <h3 class="fs-f-1-125rem my-text-black">
                {{ $t("text_introduce19")
                }}<svg
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                  focusable="false"
                  class="opacity-o-0 w-1 h-4 d-inline-block transition-all duration-500 hover-opacity-100"
                >
                  <path
                    d="M17.707 9.293l-5-5a.999.999 0 10-1.414 1.414L14.586 9H3a1 1 0 100 2h11.586l-3.293 3.293a.999.999 0 101.414 1.414l5-5a.999.999 0 000-1.414z"
                    fill="currentColor"
                  ></path>
                </svg>
              </h3>
              <p class="text-grey">
                {{ $t("text_introduce20") }}
              </p></a
            >
            <a
              class="p-4 cursor-pointer group_hover box-shadow_black col-75 w-md-80 rounded-3 flex-shrink-0 snap-center w-lg-25"
              ><img
                src="@/assets/img/main/dev-82bdbe630a35ca679000d2177f4e32d44b5455e906f0a7ff628c3eadbee38c8e.svg"
                class="mb-4"
              />
              <h3 class="fs-f-1-125rem my-text-black">
                {{ $t("text_introduce21")
                }}<svg
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                  focusable="false"
                  class="opacity-o-0 w-1 h-4 d-inline-block transition-all duration-500 hover-opacity-100"
                >
                  <path
                    d="M17.707 9.293l-5-5a.999.999 0 10-1.414 1.414L14.586 9H3a1 1 0 100 2h11.586l-3.293 3.293a.999.999 0 101.414 1.414l5-5a.999.999 0 000-1.414z"
                    fill="currentColor"
                  ></path>
                </svg>
              </h3>
              <p class="text-grey">{{ $t("text_introduce22") }}</p></a
            >
            <a
              class="p-4 cursor-pointer group_hover box-shadow_black col-75 w-md-80 rounded-3 flex-shrink-0 snap-center w-lg-25"
              ><img
                src="@/assets/img/main/creators-bea49bb4984ee33a25150a9908bd7e701781c56443b83b785060cd8e6b9bc219.svg"
                class="mb-4"
              />
              <h3 class="fs-f-1-125rem my-text-black">
                {{ $t("text_introduce23")
                }}<svg
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                  focusable="false"
                  class="opacity-o-0 w-1 h-4 d-inline-block transition-all duration-500 hover-opacity-100"
                >
                  <path
                    d="M17.707 9.293l-5-5a.999.999 0 10-1.414 1.414L14.586 9H3a1 1 0 100 2h11.586l-3.293 3.293a.999.999 0 101.414 1.414l5-5a.999.999 0 000-1.414z"
                    fill="currentColor"
                  ></path>
                </svg>
              </h3>
              <p class="text-grey">{{ $t("text_introduce24") }}</p></a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="my-bg-grey">
      <div class="py-32 container">
        <h6>{{ $t("text_introduce25") }}</h6>
        <p class="mb-3 md-fs-f-2-5rem fs-f-3rem fw-bold my-text-black">
          {{ $t("text_introduce26") }}
        </p>
        <div
          class="scroll-new d-lg-flex d-none position-relative overflow-y-hidden"
        >
          <img
            src="@/assets/img/main/site_web_01.png"
            class="w-100 snap-center"
            :class="
              index_25 == 0
                ? 'my-opacity-100 position-relative'
                : 'my-opacity-0 position-absolute start-0 top-0'
            "
          />
          <img
            src="@/assets/img/main/site_web_pic_02.png"
            class="w-100 snap-center"
            :class="
              index_25 == 1
                ? 'my-opacity-100 position-relative'
                : 'my-opacity-0 position-absolute start-0 top-0'
            "
          />
          <img
            src="@/assets/img/main/site_web_pic_03.png"
            class="w-100 snap-center"
            :class="
              index_25 == 2
                ? 'my-opacity-100 position-relative'
                : 'my-opacity-0 position-absolute start-0 top-0'
            "
          />
        </div>
        <div
          class="scroll-new d-lg-none d-flex position-relative overflow-y-hidden cp"
          ref="dialogCountent"
          @scroll="orderScroll"
        >
          <img
            src="@/assets/img/main/site_web_01.png"
            class="w-100 snap-center flex-shrink-0"
            v-show="index_25 == 0"
          />
          <img
            src="@/assets/img/main/site_web_pic_02.png"
            class="w-100 snap-center flex-shrink-0"
            v-show="index_25 == 1"
          />
          <img
            src="@/assets/img/main/site_web_pic_03.png"
            class="w-100 snap-center flex-shrink-0"
            v-show="index_25 == 2"
          />
        </div>

        <div class="w-xl-75 w-100 d-flex justify-content-between cp mt-4">
          <div
            class="d-absolute h-05 w-100 c-pointer bg-shade"
            @click="index_25 = 0"
          >
            <div
              class="w-100 h-100 bg-linear-scroll origin-left"
              style="transition-duration: 12587ms"
              :class="index_25 == 0 ? 'scale-x-100' : 'scale-x-0'"
            ></div>
          </div>
          <div
            class="d-absolute h-05 w-100 c-pointer bg-shade"
            @click="index_25 = 1"
          >
            <div
              class="w-100 h-100 bg-linear-scroll origin-left"
              style="transition-duration: 12587ms"
              :class="index_25 == 1 ? 'scale-x-100' : 'scale-x-0'"
            ></div>
          </div>
          <div
            class="d-absolute h-05 w-100 c-pointer bg-shade"
            @click="index_25 = 2"
          >
            <div
              class="w-100 h-100 bg-linear-scroll origin-left"
              style="transition-duration: 12587ms"
              :class="index_25 == 2 ? 'scale-x-100' : 'scale-x-0'"
            ></div>
          </div>
        </div>

        <div class="d-flex justify-content-between mt-5">
          <div
            class="xl-w-33 c-pointer"
            @click="index_25 = 0"
            :style="
              flag_web_h5
                ? 'display:block'
                : index_25 == 0
                ? 'display:block'
                : 'display:none'
            "
          >
            <h3 class="fs-f-125 fw-bold">{{ $t("text_introduce27") }}</h3>
            <p class="fs-f-1-125rem">
              {{ $t("text_introduce28") }}
            </p>
          </div>
          <div
            class="xl-w-33 c-pointer"
            @click="index_25 = 1"
            :style="
              flag_web_h5
                ? 'display:block'
                : index_25 == 1
                ? 'display:block'
                : 'display:none'
            "
          >
            <h3 class="fs-f-125 fw-bold">{{ $t("text_introduce29") }}</h3>
            <p class="fs-f-1-125rem">
              {{ $t("text_introduce30") }}
            </p>
          </div>
          <div
            class="xl-w-33 c-pointer"
            @click="index_25 = 2"
            :style="
              flag_web_h5
                ? 'display:block'
                : index_25 == 2
                ? 'display:block'
                : 'display:none'
            "
          >
            <h3 class="fs-f-125 fw-bold">{{ $t("text_introduce31") }}</h3>
            <p class="fs-f-1-125rem">
              {{ $t("text_introduce32") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="py-32 container">
        <div class="row">
          <div class="col-xl-8 col-12">
            <h6 class="mt-5">{{ $t("text_introduce33") }}</h6>
            <p class="mb-3 md-fs-f-2-5rem xl-fs-f-45rem fw-bold my-text-black">
              {{ $t("text_introduce34") }}
            </p>
          </div>
          <div class="col-xl-4 col-12">
            <p class="my-xl-5 mt-2 mb-4">
              {{ $t("text_introduce35") }}
            </p>
            <a
              class="group_hover border-bottom boder-black-bo my-text-black pb-3 d-flex w-75 overflow-hidden"
              ><svg
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                class="transition-all duration-500 my-opacity-0 w-6 h-6 -translate-x-full group-hover-opacity-100 group-hover-scale-x-100"
              >
                <path
                  d="M17.707 9.293l-5-5a.999.999 0 10-1.414 1.414L14.586 9H3a1 1 0 100 2h11.586l-3.293 3.293a.999.999 0 101.414 1.414l5-5a.999.999 0 000-1.414z"
                  fill="currentColor"
                ></path>
              </svg>
              <span
                class="transition-all duration-500 -translate-x-5 group-hover-scale-x-5"
                style="font-size: 18px"
              >
                {{ $t("text_introduce36") }}</span
              ><svg
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                class="ms-auto transition-all duration-500 opacity-100 w-6 h-6 scale-x-100 group-hover-opacity-0 group-hover-scale-x-0"
              >
                <path
                  d="M17.707 9.293l-5-5a.999.999 0 10-1.414 1.414L14.586 9H3a1 1 0 100 2h11.586l-3.293 3.293a.999.999 0 101.414 1.414l5-5a.999.999 0 000-1.414z"
                  fill="currentColor"
                ></path>
              </svg>
            </a>
          </div>
        </div>
        <div class="d-flex cp scroll-new mt-5">
          <img
            src="@/assets/img/main/site_pic3.png"
            class="snap-center xl-w-33 w-80vw w-lg-33"
          />
          <img
            src="@/assets/img/main/site_pic4.png"
            class="snap-center xl-w-33 w-80vw w-lg-33"
          />
          <img
            src="@/assets/img/main/site_pic5.png"
            class="snap-center xl-w-33 w-80vw w-lg-33"
          />
        </div>
      </div>
    </div>

    <div>
      <div class="py-32 container">
        <div class="row">
          <div class="col-lg-6 col-12">
            <h6 class="mt-5 mb-4">{{ $t("text_introduce37") }}</h6>
            <p
              class="mb-4 text-28px xl-fs-f-40px fw-bold my-text-black ls-002 line-height-30px xl-line-height-44px"
            >
              {{ $t("text_introduce38") }}
            </p>
            <p>
              {{ $t("text_introduce39") }}
            </p>
            <a
              class="group_hover border-bottom boder-black-bo my-text-black pb-3 d-flex w-lg-75 w-md-100 overflow-hidden mt-5"
              ><svg
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                class="transition-all duration-500 my-opacity-0 w-6 h-6 -translate-x-full group-hover-opacity-100 group-hover-scale-x-100"
              >
                <path
                  d="M17.707 9.293l-5-5a.999.999 0 10-1.414 1.414L14.586 9H3a1 1 0 100 2h11.586l-3.293 3.293a.999.999 0 101.414 1.414l5-5a.999.999 0 000-1.414z"
                  fill="currentColor"
                ></path>
              </svg>
              <span
                class="transition-all duration-500 -translate-x-5 group-hover-scale-x-5"
                style="font-size: 18px"
              >
                {{ $t("text_introduce40") }}</span
              ><svg
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                class="ms-auto transition-all duration-500 opacity-100 w-6 h-6 scale-x-100 group-hover-opacity-0 group-hover-scale-x-0"
              >
                <path
                  d="M17.707 9.293l-5-5a.999.999 0 10-1.414 1.414L14.586 9H3a1 1 0 100 2h11.586l-3.293 3.293a.999.999 0 101.414 1.414l5-5a.999.999 0 000-1.414z"
                  fill="currentColor"
                ></path>
              </svg>
            </a>
          </div>
          <div class="col-lg-6 col-12 mt-5">
            <img src="@/assets/img/main/site_pic6.png" class="w-100" />
          </div>
        </div>
      </div>
    </div>

    <div class="my-bg-grey">
      <div class="py-32 container">
        <h6 class="mt-5 mb-4">{{ $t("text_introduce41") }}</h6>
        <div class="d-flex flex-lg-row flex-column justify-content-between">
          <p
            class="mb-4 fs-f-2-5rem xl-fs-f-45rem fw-bold my-text-black ls-002 line-height-275rem"
            style="max-width: 42rem"
          >
            {{ $t("text_introduce42") }}
          </p>
          <div class="align-self-lg-end" style="min-width: 20rem">
            <a
              class="group_hover border-bottom boder-black-bo my-text-black pb-3 d-flex overflow-hidden mt-lg-5 mt-3"
              ><svg
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                class="transition-all duration-500 my-opacity-0 w-6 h-6 -translate-x-full group-hover-opacity-100 group-hover-scale-x-100"
              >
                <path
                  d="M17.707 9.293l-5-5a.999.999 0 10-1.414 1.414L14.586 9H3a1 1 0 100 2h11.586l-3.293 3.293a.999.999 0 101.414 1.414l5-5a.999.999 0 000-1.414z"
                  fill="currentColor"
                ></path>
              </svg>
              <span
                class="transition-all duration-500 -translate-x-5 group-hover-scale-x-5"
                style="font-size: 18px"
              >
                {{ $t("text_introduce43") }}</span
              ><svg
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                class="ms-auto transition-all duration-500 opacity-100 w-6 h-6 scale-x-100 group-hover-opacity-0 group-hover-scale-x-0"
              >
                <path
                  d="M17.707 9.293l-5-5a.999.999 0 10-1.414 1.414L14.586 9H3a1 1 0 100 2h11.586l-3.293 3.293a.999.999 0 101.414 1.414l5-5a.999.999 0 000-1.414z"
                  fill="currentColor"
                ></path>
              </svg>
            </a>
          </div>
        </div>
        <div
          class="d-flex justify-content-between xl-gap-16 flex-lg-row flex-column-reverse mt-5"
        >
          <div class="w-100">
            <div
              class="c-pointer"
              @click="index_41 = 0"
              :class="
                flag_web_h5 ? 'd-flex' : index_41 == 0 ? 'd-flex' : 'd-none'
              "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                class="h-6 w-6 me-4 rounded-pill bg-purple box-shadow-purple text-purple d-none d-lg-block"
              >
                <path
                  d="M10.25 4.75L7.75 19.25"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M16.25 4.75L13.75 19.25"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M19.25 8.75H5.75"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M18.25 15.25H4.75"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <div
                :class="
                  flag_web_h5 && index_41 == 0
                    ? 'border-bottom boder-black-bo'
                    : index_41 == 0
                    ? 'boder-black-bo'
                    : 'border-bottom'
                "
              >
                <h3 class="fw-bold fs-f-125 xl-fs-f-26px mb-4">
                  {{ $t("text_introduce44") }}
                </h3>
                <p class="md-fs-f-1rem fs-f-1-125rem pb-3 text-grey">
                  {{ $t("text_introduce45") }}
                </p>
              </div>
            </div>

            <div
              class="mt-lg-5 c-pointer"
              @click="index_41 = 1"
              :class="
                flag_web_h5 ? 'd-flex' : index_41 == 1 ? 'd-flex' : 'd-none'
              "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                class="me-4 rounded-pill bg-blue box-shadow-blue text-blue d-none d-lg-block"
                style="width: 7%; height: 7%"
              >
                <path
                  d="M12 15.25C13.7949 15.25 15.25 13.7949 15.25 12C15.25 10.2051 13.7949 8.75 12 8.75C10.2051 8.75 8.75 10.2051 8.75 12C8.75 13.7949 10.2051 15.25 12 15.25Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M12 19.25C7.99594 19.25 4.75 16.0041 4.75 12C4.75 7.99594 7.99594 4.75 12 4.75C18.8125 4.75 19.25 9.125 19.25 12V13.25C19.25 14.3546 18.3546 15.25 17.25 15.25C16.1454 15.25 15.25 14.3546 15.25 13.25V8.75"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <div
                :class="
                  flag_web_h5 && index_41 == 1
                    ? 'border-bottom boder-black-bo'
                    : index_41 == 1
                    ? 'boder-black-bo'
                    : 'border-bottom'
                "
              >
                <h3 class="fw-bold fs-f-125 xl-fs-f-26px mb-4">
                  {{ $t("text_introduce46") }}
                </h3>
                <p class="md-fs-f-1rem fs-f-1-125rem pb-3 text-grey">
                  {{ $t("text_introduce47") }}
                </p>
              </div>
            </div>

            <div
              class="mt-lg-5 c-pointer"
              @click="index_41 = 2"
              :class="
                flag_web_h5 ? 'd-flex' : index_41 == 2 ? 'd-flex' : 'd-none'
              "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                class="me-4 rounded-pill bg-red box-shadow-red text-red d-none d-lg-block"
                style="width: 13%; height: 13%"
              >
                <path
                  d="M6.25 8.75H5.75C5.19772 8.75 4.75 9.19772 4.75 9.75V15.25C4.75 15.8023 5.19772 16.25 5.75 16.25H6.25C6.80228 16.25 7.25 15.8023 7.25 15.25V9.75C7.25 9.19772 6.80228 8.75 6.25 8.75Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M12.25 4.75H11.75C11.1977 4.75 10.75 5.19772 10.75 5.75V15.25C10.75 15.8023 11.1977 16.25 11.75 16.25H12.25C12.8023 16.25 13.25 15.8023 13.25 15.25V5.75C13.25 5.19772 12.8023 4.75 12.25 4.75Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M4.75 19.25H19.25"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M18.25 8.75H17.75C17.1977 8.75 16.75 9.19772 16.75 9.75V15.25C16.75 15.8023 17.1977 16.25 17.75 16.25H18.25C18.8023 16.25 19.25 15.8023 19.25 15.25V9.75C19.25 9.19772 18.8023 8.75 18.25 8.75Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <div
                :class="
                  flag_web_h5 && index_41 == 2
                    ? 'border-bottom boder-black-bo'
                    : index_41 == 2
                    ? 'boder-black-bo'
                    : 'border-bottom'
                "
              >
                <h3 class="fw-bold fs-f-125 xl-fs-f-26px mb-4">
                  {{ $t("text_introduce48") }}
                </h3>
                <p class="md-fs-f-1rem fs-f-1-125rem pb-3 text-grey">
                  {{ $t("text_introduce49") }}
                </p>
              </div>
            </div>
          </div>

          <div>
            <div
              class="scroll-new d-flex position-relative overflow-y-hidden cp"
            >
              <img
                src="@/assets/img/main/site_manage_pic_01.png"
                class="w-100 snap-center flex-shrink-0"
                v-show="index_41 == 0"
              />
              <img
                src="@/assets/img/main/site_manage_pic_02.png"
                class="w-100 snap-center flex-shrink-0"
                v-show="index_41 == 1"
              />
              <img
                src="@/assets/img/main/site_manage_pic_03.png"
                class="w-100 snap-center flex-shrink-0"
                v-show="index_41 == 2"
              />
            </div>

            <div
              class="w-xl-75 w-100 d-flex d-lg-none justify-content-between cp my-4"
            >
              <div
                class="d-absolute h-05 w-100 c-pointer bg-shade"
                @click="index_41 = 0"
              >
                <div
                  class="w-100 h-100 bg-linear-scroll origin-left"
                  style="transition-duration: 0"
                  :class="index_41 == 0 ? 'scale-x-100' : 'scale-x-0'"
                ></div>
              </div>
              <div
                class="d-absolute h-05 w-100 c-pointer bg-shade"
                @click="index_41 = 1"
              >
                <div
                  class="w-100 h-100 bg-linear-scroll origin-left"
                  style="transition-duration: 0"
                  :class="index_41 == 1 ? 'scale-x-100' : 'scale-x-0'"
                ></div>
              </div>
              <div
                class="d-absolute h-05 w-100 c-pointer bg-shade"
                @click="index_41 = 2"
              >
                <div
                  class="w-100 h-100 bg-linear-scroll origin-left"
                  style="transition-duration: 0"
                  :class="index_41 == 2 ? 'scale-x-100' : 'scale-x-0'"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="py-32 container">
        <h6 class="mt-5 mb-4">{{ $t("text_introduce50") }}</h6>
        <div class="d-flex flex-lg-row flex-column justify-content-between">
          <p
            class="mb-4 fs-f-2-5rem xl-fs-f-45rem fw-bold my-text-black ls-002 line-height-275rem"
            style="max-width: 42rem"
          >
            {{ $t("text_introduce51") }}
          </p>
          <div class="align-self-lg-end" style="min-width: 20rem">
            <a
              class="group_hover border-bottom boder-black-bo my-text-black pb-3 d-flex overflow-hidden mt-lg-5 mt-3"
              ><svg
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                class="transition-all duration-500 my-opacity-0 w-6 h-6 -translate-x-full group-hover-opacity-100 group-hover-scale-x-100"
              >
                <path
                  d="M17.707 9.293l-5-5a.999.999 0 10-1.414 1.414L14.586 9H3a1 1 0 100 2h11.586l-3.293 3.293a.999.999 0 101.414 1.414l5-5a.999.999 0 000-1.414z"
                  fill="currentColor"
                ></path>
              </svg>
              <span
                class="transition-all duration-500 -translate-x-5 group-hover-scale-x-5"
                style="font-size: 18px"
              >
                {{ $t("text_introduce52") }}</span
              ><svg
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                class="ms-auto transition-all duration-500 opacity-100 w-6 h-6 scale-x-100 group-hover-opacity-0 group-hover-scale-x-0"
              >
                <path
                  d="M17.707 9.293l-5-5a.999.999 0 10-1.414 1.414L14.586 9H3a1 1 0 100 2h11.586l-3.293 3.293a.999.999 0 101.414 1.414l5-5a.999.999 0 000-1.414z"
                  fill="currentColor"
                ></path>
              </svg>
            </a>
          </div>
        </div>
        <div
          class="d-flex justify-content-between xl-gap-16 flex-lg-row-reverse flex-column-reverse mt-5"
        >
          <div class="w-100">
            <div
              class="c-pointer"
              @click="index_50 = 0"
              :class="
                flag_web_h5 ? 'd-flex' : index_50 == 0 ? 'd-flex' : 'd-none'
              "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                class="h-6 w-6 me-4 rounded-pill bg-purple box-shadow-purple text-purple d-none d-lg-block"
              >
                <path
                  d="M4.75 6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H17.25C18.3546 4.75 19.25 5.64543 19.25 6.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H6.75C5.64543 19.25 4.75 18.3546 4.75 17.25V6.75Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M8.75 15.25V9.75"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M15.25 15.25V9.75"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M12 15.25V12.75"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <div
                :class="
                  flag_web_h5 && index_50 == 0
                    ? 'border-bottom boder-black-bo'
                    : index_50 == 0
                    ? 'boder-black-bo'
                    : 'border-bottom'
                "
              >
                <h3 class="fw-bold fs-f-125 xl-fs-f-26px mb-4">
                  {{ $t("text_introduce53") }}
                </h3>
                <p class="md-fs-f-1rem fs-f-1-125rem pb-3 text-grey">
                  {{ $t("text_introduce54") }}
                </p>
              </div>
            </div>

            <div
              class="mt-lg-5 c-pointer"
              @click="index_50 = 1"
              :class="
                flag_web_h5 ? 'd-flex' : index_50 == 1 ? 'd-flex' : 'd-none'
              "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                class="h-6 w-6 me-4 rounded-pill bg-blue box-shadow-blue text-blue d-none d-lg-block"
              >
                <path
                  d="M5.75 8.87L12 12.25M5.75 8.87V15.87L12 19.25M5.75 8.87L8.875 7.31M18.25 8.87L12 5.75L8.875 7.31M18.25 8.87V15.87L12 19.25M18.25 8.87L15.125 10.56M12 12.25V19.25M12 12.25L15.125 10.56M15.125 10.56L8.875 7.31"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <div
                :class="
                  flag_web_h5 && index_50 == 1
                    ? 'border-bottom boder-black-bo'
                    : index_50 == 1
                    ? 'boder-black-bo'
                    : 'border-bottom'
                "
              >
                <h3 class="fw-bold fs-f-125 xl-fs-f-26px mb-4">
                  {{ $t("text_introduce55") }}
                </h3>
                <p class="md-fs-f-1rem fs-f-1-125rem pb-3 text-grey">
                  {{ $t("text_introduce56") }}
                </p>
              </div>
            </div>

            <div
              class="mt-lg-5 c-pointer"
              @click="index_50 = 2"
              :class="
                flag_web_h5 ? 'd-flex' : index_50 == 2 ? 'd-flex' : 'd-none'
              "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                class="h-6 w-6 me-4 rounded-pill bg-red box-shadow-red text-red d-none d-lg-block"
              >
                <path
                  d="M18.25 11.5V19.25M5.75 19.25V11.5M9.75 19.25V11.5M14.25 19.25V11.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M12 4.75L19.25 11.25H4.75L12 4.75Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M4.75 19.25H19.25"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <div
                :class="
                  flag_web_h5 && index_50 == 2
                    ? 'border-bottom boder-black-bo'
                    : index_50 == 2
                    ? 'boder-black-bo'
                    : 'border-bottom'
                "
              >
                <h3 class="fw-bold fs-f-125 xl-fs-f-26px mb-4">
                  {{ $t("text_introduce57") }}
                </h3>
                <p class="md-fs-f-1rem fs-f-1-125rem pb-3 text-grey">
                  {{ $t("text_introduce58") }}
                </p>
              </div>
            </div>
          </div>

          <div>
            <div
              class="scroll-new d-flex position-relative overflow-y-hidden cp"
            >
              <img
                src="@/assets/img/main/97ac98396226b6ac09ae8a671bf21d17.webp"
                class="w-100 snap-center flex-shrink-0"
                v-show="index_50 == 0"
              />
              <img
                src="@/assets/img/main/41f1ba5fcfaacda2759d42939d4ff459.png"
                class="w-100 snap-center flex-shrink-0"
                v-show="index_50 == 1"
              />
              <img
                src="@/assets/img/main/63870b37686c4dc3dcae41db74dee143.webp"
                class="w-100 snap-center flex-shrink-0"
                v-show="index_50 == 2"
              />
            </div>

            <div
              class="w-xl-75 w-100 d-flex d-xl-none justify-content-between cp my-4"
            >
              <div
                class="d-absolute h-05 w-100 c-pointer bg-shade"
                @click="index_50 = 0"
              >
                <div
                  class="w-100 h-100 bg-linear-scroll origin-left"
                  style="transition-duration: 0"
                  :class="index_50 == 0 ? 'scale-x-100' : 'scale-x-0'"
                ></div>
              </div>
              <div
                class="d-absolute h-05 w-100 c-pointer bg-shade"
                @click="index_50 = 1"
              >
                <div
                  class="w-100 h-100 bg-linear-scroll origin-left"
                  style="transition-duration: 0"
                  :class="index_50 == 1 ? 'scale-x-100' : 'scale-x-0'"
                ></div>
              </div>
              <div
                class="d-absolute h-05 w-100 c-pointer bg-shade"
                @click="index_50 = 2"
              >
                <div
                  class="w-100 h-100 bg-linear-scroll origin-left"
                  style="transition-duration: 0"
                  :class="index_50 == 2 ? 'scale-x-100' : 'scale-x-0'"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="bg-gradient-marquee xl-fs-f-1-875rem fs-f-125 fw-bold h-4 xl-h-5"
    >
      <div class="animate-marquee d-flex align-items-center h-100 text-nowrap">
        <div v-for="l in 20" :key="l" class="d-inline-block">
          {{ $t("text_free_use") }}
          <span class="mx-5"
            ><svg
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              class="w-6 h-6 xl-w-8 xl-h-8"
            >
              <path
                d="M17.707 9.293l-5-5a.999.999 0 10-1.414 1.414L14.586 9H3a1 1 0 100 2h11.586l-3.293 3.293a.999.999 0 101.414 1.414l5-5a.999.999 0 000-1.414z"
              ></path>
            </svg>
          </span>
        </div>
      </div>
    </div>

    <div class="container mt-5 d-none d-lg-block">
      <img
        src="@/assets/img/main/video.jpg"
        class="w-100 h-100"
        style="filter: blur(4px)"
      />
    </div>

    <div class="container mt-5">
      <div class="row flex-column-reverse flex-lg-row">
        <div class="mb-5 col-lg-6 col-12 mt-xl-0 mt-5">
          <q
            class="lg-fs-f-46 fs-15rem lg-fs-f-30 xl-line-height-113em fw-bold"
            >{{ list_recommend[index_recommend]["r1_1"] }}</q
          >
          <p class="mt-5 xl-fs-f-1-875rem fs-15rem fw-bold">
            {{ list_recommend[index_recommend]["r1_2"] }}
          </p>
          <p class="">
            <span class="fw-bold"
              >{{ list_recommend[index_recommend]["r1_3"] }} </span
            >— {{ list_recommend[index_recommend]["r1_4"] }}
          </p>

          <div class="">
            <button
              name="button"
              type="button"
              class="rounded-circle w-12 h-12 bg-transparent me-2"
              style="padding: 0.875rem"
              @click="
                index_recommend == 0
                  ? (index_recommend = 1)
                  : (index_recommend = 0)
              "
            >
              <svg viewBox="0 0 20 20" class="d-block">
                <path
                  d="M17 9H5.414l3.293-3.293a.999.999 0 10-1.414-1.414l-5 5a.999.999 0 000 1.414l5 5a.997.997 0 001.414 0 .999.999 0 000-1.414L5.414 11H17a1 1 0 100-2z"
                ></path>
              </svg>
            </button>
            <button
              name="button"
              type="button"
              class="rounded-circle w-12 h-12 bg-transparent"
              style="padding: 0.875rem"
              @click="
                index_recommend == 0
                  ? (index_recommend = 1)
                  : (index_recommend = 0)
              "
            >
              <svg viewBox="0 0 20 20" class="d-block">
                <path
                  d="M17.707 9.293l-5-5a.999.999 0 10-1.414 1.414L14.586 9H3a1 1 0 100 2h11.586l-3.293 3.293a.999.999 0 101.414 1.414l5-5a.999.999 0 000-1.414z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <div
          class="mb-5 col-lg-6 col-12 position-relative d-flex justify-content-center"
          style="aspect-ratio: 18/15"
        >
          <img
            src="@/assets/img/main/site_pic7-1.png"
            class="position-lg-absolute position-relative ms-lg-5"
            :style="style_img_recommend('img1')"
          />
          <img
            src="@/assets/img/main/site_pic7-2.png"
            class="position-lg-absolute position-relative ms-lg-5"
            :style="style_img_recommend('img2')"
          />
        </div>
      </div>
    </div>

    <div class="py-32 mt-5 my-bg-black">
      <div class="container">
        <p class="text-green text-xl-center text-left">
          {{ $t("text_introduce60") }}
        </p>
        <p
          class="my-text-white xl-fs-f-45rem fs-f-2-5rem text-xl-center text-left ls-002 mt-4 fw-bold"
        >
          {{ $t("text_introduce61") }}
        </p>
      </div>
      <div
        class="d-flex cp-2 mx-auto justify-content-xl-center justify-content-start lg-mt-8 scroll-new"
      >
        <div
          class="p-5 rounded-4 xl-w-25vw md-w-70vw lg-w-45vw mb-5 snap-center flex-shrink-0"
          style="
            border: 1px solid rgb(27 28 28);
            background: linear-gradient(
                rgb(10, 10, 10) 0%,
                rgba(5, 5, 5, 0.58) 100%
              ),
              linear-gradient(0deg, rgb(27, 28, 28), rgb(27, 28, 28));
          "
        >
          <div
            class="w-14 h-14 d-flex justify-content-center"
            style="
              background: linear-gradient(46.87deg, #5576ed 0%, #6ffd96 96.94%);
              border-radius: 14px;
            "
          >
            <svg
              viewBox="0 0 24 24"
              fill="none"
              class="h-6 w-6 align-self-center"
            >
              <path
                d="M7 15.25C7.69036 15.25 8.25 14.6904 8.25 14C8.25 13.3096 7.69036 12.75 7 12.75C6.30964 12.75 5.75 13.3096 5.75 14C5.75 14.6904 6.30964 15.25 7 15.25Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M12 15.25C12.6904 15.25 13.25 14.6904 13.25 14C13.25 13.3096 12.6904 12.75 12 12.75C11.3096 12.75 10.75 13.3096 10.75 14C10.75 14.6904 11.3096 15.25 12 15.25Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M9 11.25C9.69036 11.25 10.25 10.6904 10.25 10C10.25 9.30964 9.69036 8.75 9 8.75C8.30964 8.75 7.75 9.30964 7.75 10C7.75 10.6904 8.30964 11.25 9 11.25Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M15 11.25C15.6904 11.25 16.25 10.6904 16.25 10C16.25 9.30964 15.6904 8.75 15 8.75C14.3096 8.75 13.75 9.30964 13.75 10C13.75 10.6904 14.3096 11.25 15 11.25Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M12 7.25C12.6904 7.25 13.25 6.69036 13.25 6C13.25 5.30964 12.6904 4.75 12 4.75C11.3096 4.75 10.75 5.30964 10.75 6C10.75 6.69036 11.3096 7.25 12 7.25Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M17 15.25C17.6904 15.25 18.25 14.6904 18.25 14C18.25 13.3096 17.6904 12.75 17 12.75C16.3096 12.75 15.75 13.3096 15.75 14C15.75 14.6904 16.3096 15.25 17 15.25Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M9.5 19.25C9.5 19.25 9.25001 16.75 7 16.75C4.74999 16.75 4.75 19.25 4.75 19.25"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M14.5 19.25C14.5 19.25 14.25 16.75 12 16.75C9.74999 16.75 9.5 19.25 9.5 19.25"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M19.25 19.25C19.25 19.25 19.25 16.75 17 16.75C14.75 16.75 14.5 19.25 14.5 19.25"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>

          <h3 class="fs-f-2-5rem my-text-white mt-4">
            {{ $t("text_introduce62") }}
          </h3>
          <p class="my-text-white mt-4 fs-f-1-125rem">
            {{ $t("text_introduce63") }}
          </p>
        </div>

        <div
          class="p-5 rounded-4 xl-w-25vw md-w-70vw lg-w-45vw mb-5 snap-center flex-shrink-0"
          style="
            border: 1px solid rgb(27 28 28);
            background: linear-gradient(
                rgb(10, 10, 10) 0%,
                rgba(5, 5, 5, 0.58) 100%
              ),
              linear-gradient(0deg, rgb(27, 28, 28), rgb(27, 28, 28));
          "
        >
          <div
            class="w-14 h-14 d-flex justify-content-center"
            style="
              background: linear-gradient(46.87deg, #5576ed 0%, #6ffd96 96.94%);
              border-radius: 14px;
            "
          >
            <svg
              viewBox="0 0 24 24"
              fill="none"
              class="h-6 w-6 align-self-center"
            >
              <path
                d="M19.25 12C19.25 16.0041 16.0041 19.25 12 19.25C7.99594 19.25 4.75 16.0041 4.75 12C4.75 7.99594 7.99594 4.75 12 4.75C16.0041 4.75 19.25 7.99594 19.25 12Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M9.75 10C9.75 10 10 7.75 12 7.75C14 7.75 14.25 9 14.25 10C14.25 10.7513 13.8266 11.5027 12.9798 11.8299C12.4647 12.0289 12 12.4477 12 13V13.25"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M12.5 16C12.5 16.2761 12.2761 16.5 12 16.5C11.7239 16.5 11.5 16.2761 11.5 16C11.5 15.7239 11.7239 15.5 12 15.5C12.2761 15.5 12.5 15.7239 12.5 16Z"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>

          <h3 class="fs-f-2-5rem my-text-white mt-4">
            {{ $t("text_introduce64") }}
          </h3>
          <p class="my-text-white mt-4 fs-f-1-125rem">
            {{ $t("text_introduce65") }}
          </p>
        </div>
      </div>
    </div>

    <div class="my-bg-green">
      <div class="py-32 md-py-5 container">
        <h1 class="fw-bold text-center xl-fs-f-3-75rem md-fs-f-2-5rem">
          {{ $t("text_introduce66") }}
        </h1>
        <p
          class="fs-f-normal md-fs-f-1-125rem text-center mt-5 mx-auto"
          style="max-width: 720px"
        >
          {{ $t("text_introduce67") }}
        </p>
        <div class="text-center mt-5">
          <button
            class="my-bg-black my-text-white rounded-5 py-3 px-4 fw-bold fs-f-18 bg-hover-grey border-0"
            name="button"
            type="button"
            @click="click_href('reg')"
          >
            {{ $t("text_free_use") }}
          </button>
        </div>
      </div>
    </div>
    <div class="px-lg-3">
      <footerview class="mt-5" />
    </div>
  </div>
</template>

<script>
import maintopview from "./main_top.vue";
import footerview from "./footer.vue";
import hometop from "./home_top.vue";
export default {
  name: "Home",
  data() {
    return {
      index_25: 0,
      index_41: 0,
      index_50: 0,
      index_recommend: 0,
      list_recommend: [
        {
          r1_1: this.$t("text_introduce59"),
          r1_2: "BLK & Bold",
          r1_3: "OnlineSlotX",
          r1_4: this.$t("text_shopkeeper"),
        },
        {
          r1_1: this.$t("text_introduce123"),
          r1_2: "Mr.Kim",
          r1_3: "Casino Empire",
          r1_4: this.$t("text_shopkeeper"),
        },
      ],
      flag_web_h5: false,
      dialogCountent_scrollTop: 0,
    };
  },
  created() {
    // if (this.$Utils.isMobile() == "pc端") this.flag_web_h5 = true;
    // else this.flag_web_h5 = false;
    if (screen.width >= 992) {
      this.flag_web_h5 = true;
    } else this.flag_web_h5 = false;
  },
  mounted() {},
  components: {
    maintopview,
    footerview,
    hometop,
  },
  computed: {},
  methods: {
    click_href(id) {
      if (id == "reg") window.open("https://betnetbk.starlink.pw/reg.aspx");
      else window.open("https://betnetbk.starlink.pw/");
    },
    orderScroll() {
      this.dialogCountent_scrollTop = this.$refs.dialogCountent.scrollLeft;
    },
    style_img_recommend(who_img) {
      if (this.flag_web_h5) {
        if (who_img == "img1") {
          if (this.index_recommend == 0)
            return "opacity: 1;transition: transform 2s linear 0s;animation: 700ms ease-out 0s 1 normal forwards running;z-index: 1;width: 67%;";
          else
            return "opacity: 0.5;transform: rotateZ(-3.5deg) translate(5px, 10px) scale(0.9);width: 67%;";
        } else {
          if (this.index_recommend == 1)
            return "opacity: 1;transition: transform 2s linear 0s;animation: 700ms ease-out 0s 1 normal forwards running;width: 67%;";
          else
            return "opacity: 0.5;transition: transform 2s linear 0s;transform: rotateZ(7deg) translate(10px, 20px) scale(1);width: 67%;";
        }
      } else {
        if (who_img == "img1") {
          if (this.index_recommend == 0)
            return "opacity: 1;margin:auto;max-width: 20rem;";
          else return "display: none;";
        } else {
          if (this.index_recommend == 1)
            return "opacity: 1;margin:auto;max-width: 20rem;";
          else return "display: none;";
        }
      }
    },
  },
  watch: {
    dialogCountent_scrollTop(newval) {
      let clientHeight = this.$refs.dialogCountent.clientWidth + 16;
      if (clientHeight * 1 == newval) this.index_25 = 1;
      else if (clientHeight * 2 == newval) this.index_25 = 2;
      else if (clientHeight * 0 == newval) this.index_25 = 0;
    },
  },
};
</script>
